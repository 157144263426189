import {
    $,
    $$
} from './../util/qsa'
import {
    gsap,
    Back,
    Power2,
    Power4
} from 'gsap'

const intro = $('.intro')

const randX = gsap.utils.random(0, 100, true)
const randY = gsap.utils.random(5, 50, true)
const randSize = gsap.utils.random(80, 350, true)

const flashes = Array(15).fill(1).map(i => {
    const x = randX()
    const y = randY()
    const size = randSize()

    const d = document.createElement('div')
    d.className = 'flash'
    d.style.top = `${y}vh`
    d.style.left = `${x}vw`
    d.style.width = `${size}px`
    d.style.height = `${size}px`

    intro.appendChild(d)

    return d
})

const introAnim = gsap.timeline({ paused: true })
introAnim.to('.intro__crowd', { x: '-75%', duration: 4, ease: window.innerWidth > 600 ? Power2.easeOut : Power4.easeOut })

// Roster
introAnim.fromTo('.intro__roster .in-1', { opacity: 0, y: 0 }, { opacity: 1, y: -20, duration: .5 })
introAnim.fromTo('.intro__roster .in-2', { opacity: 0, y: 0 }, { opacity: 1, y: -20, duration: .5 }, '-=.2')

// Flashes
const flashAnim = gsap.timeline()

flashes.forEach((flash, index) => {
    flashAnim.to(flash, { opacity: 1, scale: 1, duration: .1 })

    if (index < flashes.length - 5) {
        flashAnim.to(flash, { opacity: 0, scale: 0, duration: .1 })
    }
})

introAnim.add(flashAnim, '-=.5')

introAnim.to('.site-header__brand', { y: 0, duration: .5, ease: Back.easeOut }, '-=2')
introAnim.set('.site-header__brand', { className: 'site-header__brand  shine'}, '-=.7')
introAnim.fromTo('.site-header__social-links, .site-header__cta', { opacity: 0 }, { opacity: 1, duration: .7 }, '+=.2')

window.addEventListener('load', () => introAnim.play())