import { $, $$ } from './../util/qsa'

const header = $('.site-header')

let scrollTick = false
let lastScrollPos = 0

window.addEventListener('scroll', () => {
    if (scrollTick) {
        return
    }

    window.requestAnimationFrame(() => {
        const { scrollY } = window

        header.classList.toggle('site-header--up', scrollY > 80 && scrollY > lastScrollPos)

        scrollTick = false
        lastScrollPos = scrollY
    })

    scrollTick = true
})

