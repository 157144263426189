import { $$ } from '../util/qsa'
import MicroModal from 'micromodal'

const handleLightbox = e => {
    // Set image content
    const image = e.target.getAttribute('data-lightbox')
    document.getElementById('lightbox-content').innerHTML = `<img src="${image}" alt="">`

    // Show modal
    MicroModal.show('lightbox', {
        disableScroll: true
    })
}

$$('[data-lightbox]').forEach(el => el.addEventListener('click', handleLightbox, { passive: true }))